.index .header {
  background-color: rgba(0, 0, 0, 0.25);
}

.index .sticky {
  background-color: white;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 10vh;
  padding: 0 3vw;
  color: white;
  background-color: #353D64;
  border-bottom: 1px solid #000;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  -webkit-animation: headerOpacity 2s linear;
          animation: headerOpacity 2s linear;
  z-index: 100;
  -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
  color: #000;
}

@-webkit-keyframes headerOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes headerOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sticky + .hero {
  padding-top: 18px;
}

/* ---------------------------------- Logo ---------------------------------- */
.overlay,
.fixed-action-btn {
  display: none;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.logo_icon {
  width: 40px;
  height: 40px;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-repeat: no-repeat;
}

.logo__title {
  margin: 0;
  color: inherit;
  font-size: clamp(1.3rem, 2vw, 3rem);
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
}

.nav a:hover {
  color: #21c0ff;
  text-decoration: underline;
}

/* ------------------------------- 4 Languages ------------------------------ */
.language-list {
  position: fixed;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
  padding: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px 0 0 5px;
  text-align: center;
  z-index: 100;
}

.language-list input {
  display: none;
}

.language-list input:checked + label {
  text-decoration: underline;
}

.language-list label {
  font-size: calc(1.255rem + 0.06vw);
}

@media (min-width: 1200px) {
  .language-list label {
    font-size: 1.3rem;
  }
}

.language-list label:hover {
  color: #21c0ff;
}

/* --------------------------------- Mobile --------------------------------- */
@media screen and (max-width: 1250px) {
  .overlay,
  .fixed-action-btn {
    display: block;
  }
  .nav {
    display: none;
  }
}
